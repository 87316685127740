import Button from "components/general/buttonv2";
import {
  StyledBody,
  StyledHalf,
  StyledHalfImage,
  StyledSvgWrapper,
} from "components/general/stylingHero";
import asyncComponent from "components/split/asyncComponent";
import { DISCORD_LINK, downloadButtonText, downloadsDisabled } from "data/variables";
import styled from "styled-components";
const CtaGif = asyncComponent(() => import("components/home/ctaGif"));

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  position: relative;
  padding: 4rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 0 2rem 0;
  }
`;
const StyledButtonRow = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 1rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    flex-direction: column;
    svg {
      margin-top: 1rem;
    }
  }
`;

const StyledWrapper = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  padding: 0rem 2rem 0 2rem;
  background: var(--background-color);
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 4rem 2rem 0 2rem;
  }
  h2 {
    font-size: 3rem;
    line-height: 4.2rem;
    font-weight: 700;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
    @media screen and (max-width: 992px) {
      line-height: 2.8rem;
      font-size: 2rem;
    }
    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
`;

const Cta = () => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledHalf tabletFlex="2">
          <h2>Download Redact Today</h2>
          <StyledBody>
            We are frequently adding support to other social network services. You can receive
            direct updates from us in Redact’s Discord Channel.
          </StyledBody>
          <StyledButtonRow>
            <Button
              alignSelf="center"
              disabled={downloadsDisabled}
              to="/download"
              data-action-name="Cta: Download"
              buttonPrimary={true}
            >
              {downloadButtonText}
            </Button>
            <Button alignSelf="center" href={DISCORD_LINK} data-action-name="Cta: Join Discord">
              Join Discord
            </Button>
          </StyledButtonRow>
        </StyledHalf>
        <StyledHalfImage>
          <StyledSvgWrapper>
            <CtaGif />
          </StyledSvgWrapper>
        </StyledHalfImage>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Cta;
