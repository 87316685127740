import styled from "styled-components";

const SvgTitle = () => (
// eslint-disable-next-line @next/next/no-img-element
  <img src="/images/svgs/delete_with_x.svg"  alt="Your deletion method of choice"/>
);
const SvgOne = () => (
  // eslint-disable-next-line @next/next/no-img-element
    <img src="/images/svgs/calendar.svg"  alt="Scheduled deletion"/>
);
const SvgTwo = () => (
  // eslint-disable-next-line @next/next/no-img-element
  <img src="/images/svgs/trash.svg"  alt="Complete deletion"/>
);
const SvgThree = () => (
  // eslint-disable-next-line @next/next/no-img-element
  <img src="/images/svgs/lines.svg"  alt="Specify rules of deletion"/>
);
const SvgFour = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
  </svg>
);

const StyledGrid = styled.div`
  display: flex;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;
const StyledLeft = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;
const StyledItem = styled.div`
  display: flex;
  padding-bottom: 2rem;
  h3 {
    margin: 0 0 0.5rem 0;
  }
  position: relative;
  ${(props) =>
    !props.lastItem &&
    `
    &:before {
      z-index: 0;
      content: '';
      position: absolute;
      left: 1.5rem;
      top: 0;
      height: 100%;
      width: 100%;
      border-left: 2px dashed #BCFDF5;
      pointer-events: none;
    }`}
`;
const StyledSvgWrapper = styled.div`
  height: 3rem;
  width: 3rem;
  position: relative;
  border-radius: 3rem;
  background: var(--raised-background-color);
  border: 1px solid var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 2rem;
  svg, img {
    fill: #50d6cd;
    height: 1.5rem;
    width: 1.5rem;
  }
`;
const StyledRight = styled.div`
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 4rem;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
const StyledRow = styled.div`
  display: flex;
  margin: ${(props) => props.margin || "0 -3rem 0 3rem"};
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;
const StyledRightItem = styled.div`
  background-color: var(--raised-background-color);
  border: 1px solid var(--background-color);
  border-radius: 5px;
  margin: 1rem;
  flex: 1 1 50%;
  position: relative;
  padding: 2rem;
  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
  }
  svg, img {
    height: 2rem;
    width: 2rem;
    fill: #50d6cd;
  }
`;
const StyledBlobOne = styled.div`
  position: absolute;
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  left: -3rem;
  bottom: -3rem;
  background: linear-gradient(180deg, #9d7de9 0%, #6890fa 100%);
`;
const StyledBlobTwo = styled.div`
  position: absolute;
  z-index: -1;
  width: 8rem;
  height: 8rem;
  border-radius: 8rem;
  right: -3rem;
  top: -3rem;
  background: linear-gradient(180deg, #9d7de9 0%, #6890fa 100%);
`;

const Grid = () => {
  return (
    <StyledGrid>
      <StyledLeft>
        <StyledItem>
          <StyledSvgWrapper>
            <SvgOne />
          </StyledSvgWrapper>
          <div>
            <h3>Scheduled Deletion</h3>
            <div>
              You have an option to create a schedule when you’re filling up the deletion form.
              These schedules can be executed preemptively, set to daily, weekly or yearly depending
              on your preference.
            </div>
          </div>
        </StyledItem>
        <StyledItem>
          <StyledSvgWrapper>
            <SvgTwo />
          </StyledSvgWrapper>
          <div>
            <h3>Complete Deletion</h3>
            <div>
              Wipe out everything! Posts, comments, embeds, whatever you can think of. When you use
              this option, nothing will be left, and all the activity on your account will be
              deleted.
            </div>
          </div>
        </StyledItem>
        <StyledItem>
          <StyledSvgWrapper>
            <SvgThree />
          </StyledSvgWrapper>
          <div>
            <h3>Specify rules of deletion</h3>
            <div>
              Input rules and specify the dates, categories etc. You can also preview before
              deleting. This is a safer process of deletion, and is recommended for most users.
            </div>
          </div>
        </StyledItem>
        <StyledItem lastItem={true}>
          <StyledSvgWrapper>
            <SvgFour />
          </StyledSvgWrapper>
          <div>
            <h3>Preview Mode</h3>
            <div>
              Preview before deleting or use our filters to search your logs and past posts.
            </div>
          </div>
        </StyledItem>
      </StyledLeft>
      <StyledRight>
        <StyledRow margin="0 0 0 0">
          <StyledRightItem>
            <h3>
              Specify rules of deletion <SvgThree />
            </h3>
            <div>Tailor your deletion to specific dates, categories, and more.</div>
          </StyledRightItem>
          <StyledRightItem>
            <h3>
              Complete Deletion <SvgTwo />
            </h3>
            <div>Wipe out everything.</div>
            <StyledBlobTwo />
          </StyledRightItem>
        </StyledRow>
        <StyledRow>
          <StyledRightItem>
            <h3>
              Scheduled Deletion <SvgOne />
            </h3>
            <div>Pick the time and date for removal</div>
            <StyledBlobOne />
          </StyledRightItem>
          <StyledRightItem>
            <h3>
              Preview Mode <SvgFour />
            </h3>
            <div>Make sure nothing is getting caught in the crossfire.</div>
          </StyledRightItem>
        </StyledRow>
      </StyledRight>
    </StyledGrid>
  );
};

const StyledSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  align-items: center;
  padding: 1rem 2rem 4rem 2rem;
  h2 {
    font-size: 3rem;
    line-height: 4.2rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    @media screen and (max-width: 1280px) {
      margin: 2rem 0 0 0;
    }
    @media screen and (max-width: 992px) {
      line-height: 2.8rem;
      font-size: 2rem;
    }
    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
  p {
    text-align: center;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 4rem;
    @media screen and (max-width: 768px) {
      text-align: left;
      margin-bottom: 1rem;
    }
  }
`;

const SubHero = () => {
  return (
    <StyledSection>
      <StyledWrapper>
        <SvgTitle />
        <h2>Your deletion method of choice</h2>
        <p>
          Choose from variety of deletion options that would suit your needs. This ranges from
          previews to schedulers, all for completely refreshing your social network profiles.
        </p>
        <Grid />
      </StyledWrapper>
    </StyledSection>
  );
};

export default SubHero;
