import { useTheme } from "components/services/themeContext";
import styled from "styled-components";

const Video = ({ className, src }) => {
  const { theme } = useTheme();
  const mp4Src = theme === "light" ? "/videos/herovideo_white.mp4" : "/videos/herovideonew3.mp4";

  const webmSrc = theme === "light" ? "/videos/herovideo_white.webm" : "/videos/herovideonew3.webm";
  return (
    <video key={`${theme}-video`} playsInline autoPlay={true} muted loop className={className}>
      <source src={webmSrc} type="video/webm" />
      <source src={mp4Src} type="video/mp4" />
    </video>
  );
};
const StyledVideo = styled(Video)`
  width: 100%;
  height: auto;
  position: relative;
  background: transparent url("/images/heropreview3.jpg") no-repeat 0 0;
  background-size: cover;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const CtaGif = () => {
  return <StyledVideo />;
};

export default CtaGif;
